<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="create-view-ticket" slot="component">
      <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" class="create-ticket-content" slot="component">
      <p class="create-ticket-content-title">{{ $t("create new ticket") }}</p>
      <form @submit.prevent="createTicket">
        <div class="form-group">
          <div class="error-container" v-if="formError">
            <p class="error">{{ $t(formError) }}</p>
          </div>

          <!-- CATEGORY -->
          <div class="form-control"
          >
            <label for="category">{{ categoryVal }}</label>

            <CustomSelect
          :options="categoriesList"
          :displayKey=" localDisplayLang === 'ml' ? 'categoryTitle.ar' : 'categoryTitle.en'"
           width="max"
           v-if="categoriesList.length > 0"
           @onSelectChange="onItemSelected"
        />
          </div>

          <div class="form-control">
          <label for="title">
                {{ $t("title") }}
                <span style="color:red;">*</span>
           </label>
                <input type="text" autocomplete="off" v-model="title" id="title" />
           </div>                                              
          <div class="form-control">
            <label for="description">
              {{ $t("message") }} : 
              <span style="color:red;">*</span>
            </label>
            <textarea id="description" v-model="description" rows="4" cols="50" maxlength="256"></textarea>
            <span class="charVal" :class="[localDisplayLang === 'eng' ? 'default' : 'flipped']">
              <span id="desc-chars">{{ maxChars }}</span> {{ $t("characters") }}
            </span>
          </div>
        </div>
        <div class="create-ticket-content-upload">
          <input
            type="file"
            accept="image/jpeg, image/png"
            class="file-input"
            :value="uploadedFileVal"
            @change="attachFile($event)"
          />
          <p class="upload">{{ $t("Attach Doc") }}</p>
        </div>
        <div class="create-ticket-attachments" v-if="imagesUploadedArr.length !== 0">
          <div class="create-ticket-attachments-file" v-for="(image, index) in imagesUploadedArr" :key="index">
            <div class="create-ticket-attachments-file-details">
              <!-- <div class="create-ticket-attachments-file-details-image">
                <img :src="image.pic" alt />
              </div> -->
              <div class="create-ticket-attachments-file-details-name">
                <span class="create-ticket-attachments-file-name">{{ image.name }}</span>
              </div>
            </div>
            <img class="delete-file" src="@/assets/icons/ClosePopup.svg" @click="deleteAttachment(image)" alt />
          </div>
        </div>

        <!--
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"

        -->
        <div class="create-ticket-footer">
          <button
            class="button-primary submit"
            type="submit"
          >
            {{ $t("create") }}
          </button>

          <div v-if="showLoader">
            <Loading></Loading>
          </div>
        </div>
      </form>
    </div>
    </div>
    
  </Popup>
</template>

<script>
import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { _providerId } from "@/provider-config.js";

import Utility from "@/mixins/Utility";
import { showSnackBar } from '../../../utilities';

export default {
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    JhakaasInput: () => import("@/components/utils/JhakaasInput.vue"),
    CustomSelect: () => import("@/components/utils/CustomSelect.vue"),
  },

  props: {
    closePopup: {
      type: Function,
    },
  },

  mixins: [Utility],

  data() {
    return {
      width: "32%",
      margin: "10% auto",
      radiusType: "full",
      title: "",
      category: null,
      description: "",
      formError: null,
      attachments: [],
      fileAttachments: [],
      imageUrl: null,
      showLoader: false,
      maxChars: 256,
      uploadedFileVal: null,
      providerUniqueId: _providerId,

      imageFileUploadPayload: null,
      imagesUploadedArr: [],
      attachmentedFiles: [],
      localDisplayLang: null,

      categoriesList: null,
      defaultSelectedCategory: null,
      tempValue : null,
      categoryVal :null
    };
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.imagesUploadedArr = [];

    this.categoriesList = this.appConfig.ticketCategories;
  },

  mounted() {
    this.tempValue = this.$t('title') + " : ",
    this.categoryVal = this.$t('choose category') + " : ",
    
    eventBus.$on("create-ticket-error", (value) => {
      console.log("THE ERROR ->", value);
      this.formError = value;
    });

    eventBus.$on("create-ticket-success", () => {
      // snackbar.show({
      //   text: "Ticket Successfully Created",
      //   pos: "bottom-center",
      // });

      showSnackBar(this.$t("Ticket Successfully Created"));

      this.$emit("refreshList");

      this.closePopup();
    });

   this.category = this.categoriesList[0].apiCategory;

    if (this.localDisplayLang === "eng") {
      this.title = this.categoriesList[0].categoryTitle.en;
    } else if (this.localDisplayLang === "ml") {
      this.title = this.categoriesList[0].categoryTitle.ar;
    }
  },

  computed: {
    ...mapGetters(["subscriberid", "appConfig"]),
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },

    attachmentedFiles(val) {
      if (val && val.length > 0) {
        if (this.imagesUploadedArr.length === val.length) {
          let payload = {
            title: this.title,
            message: this.description,
          };

          if (this.category) {
            payload.category = this.category;
          }

          if (this.attachmentedFiles.length) {
            payload.filename = JSON.stringify(this.attachmentedFiles);
          }

          console.log("The files are attached successfully, Payload ===>", payload);
          // this.createTicket(true);
          eventBus.$emit("create-ticket", payload);
          this.showLoader = false;
        }
      }
    },

    description(val) {
      document.getElementById("desc-chars").innerHTML = 256 - val.length;
    },
  },

  methods: {
    deleteAttachment(value) {
      let getIndex = this.imagesUploadedArr.findIndex((el) => el.name === value.name);
      console.log("Delete file index", getIndex);
      if (getIndex > -1) {
        this.imagesUploadedArr.splice(getIndex, 1);
      }
    },
    changeTitle(val) {
      console.log("THE FUNCTION TRIGGRED", val, this.localDisplayLang);

      this.categoriesList.forEach((element, index) => {
        console.log("THE VALUE -------", val);
        if (element.apiCategory === val) {
          if (this.localDisplayLang === "eng") {
            this.title = this.categoriesList[index].categoryTitle.en;
          } else if (this.localDisplayLang === "ml") {
            this.title = this.categoriesList[index].categoryTitle.ar;
          }
        }
      });
    },
    onItemSelected(payload){
  //  alert(payload.selectedItem.apiCategory)
      this.category = payload.selectedItem.apiCategory;
      let apiCategory = payload.selectedItem.apiCategory;
      this.changeTitle(apiCategory);
    },

    uploadImageAttachments() {
      if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
        console.log("The image file upload func is called");

        this.imagesUploadedArr.forEach((image, index) => {
          let fileObj = {
            isTicket: true,
            index: index,
            params: {
              filename: image,
            },
          };
          console.log(`The ${index} upload call ---> `, fileObj);

          eventBus.$emit("fileUpload", fileObj);

          eventBus.$on(`file-upload-response-${index}`, (data) => {
            console.log("THE UPLOAD ERROR ->", data);

            if (data.reason) {
              this.showLoader = false;
              this.formError = this.$t(data.reason);
            } else {
              this.attachmentedFiles.push(data.success);
              console.log("the response for " + index + " ----->", data);
            }
            eventBus.$off(`file-upload-response-${index}`);
          });
        });
      }
    },

    createTicket() {
      if (!this.title && !this.description) {
        this.formError = this.$t("Title and Description is required");
        return false;
      }

      if (!this.validateFormField(this.title, "title") || !this.validateFormField(this.description, "desc")) {
        return;
      }

      if (!this.showLoader) {
        this.showLoader = true;

        let payload = {
          title: this.title,
          message: this.description,
        };

        if (this.category) {
          payload.category = this.category;
        }

        if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
          console.log("Image is present, hence calling image upload first");
          this.uploadImageAttachments();
        } else {
          console.log("Image is not present, hence calling create directly");
          eventBus.$emit("create-ticket", payload);
          this.showLoader = false;
        }
      }
    },

    validateFormField(value, type) {
      this.formError = null;
      const newValue = value.trim();

      if (!newValue && type === "title") {
        this.formError = this.$t("Please enter Title");
        return false;
      }

      if (!newValue && type === "desc") {
        this.formError = this.$t("Please enter Description");
        return false;
      }

      if (newValue.length > 64 && type === "title") {
        this.formError = this.$t("Title can not be more than 64 characters");
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./createTicket.scss"

</style>
